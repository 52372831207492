<template>
  <div class="h-screen relative onboarding-font">
    <div class="flex items-start justify-between h-full">
      <div :class="onboardingStep === 0 ? 'bg-white dark-mode:bg-dark-mode-90' : 'bg-blue-20 dark-mode:bg-dark-mode-80'" class="flex-1 h-full px-6 xl:px-0">
        <div class="h-full flex items-start md:items-center justify-center overflow-y-auto without-scrollbar py-6 md:py-0" v-if="onboardingStep === 0">
          <div class="text-center">
            <a href="/" class="flex md:hidden mb-6 items-center justify-center">
              <app-logo class="dark-mode:hidden" />
              <dark-logo class="hidden dark-mode:block" />
            </a>
            <h1 class="font-bold text-32 md:text-7xl leading-10 md:leading-91 mb-6 md:mb-8 dark-mode:text-white">Welcome to <span class="block text-56 leading-70 md:leading-91 md:text-7xl">Hypefury</span></h1>
            <p class="leading-6 mb-8 dark-mode:text-white" v-if="!isMobile">Hypefury makes it easy to grow your Twitter.</p>
            <p class="leading-6 mb-8 dark-mode:text-white" v-else>Grow all your social media accounts from one dashboard.</p>
            <div class="flex md:hidden items-center justify-center w-3/4 mx-auto mt-8 mb-16">
            </div>
            <div v-if="isAccountBeingDeleted">
              <p class="leading-6 mb-8 dark-mode:text-white">
                Your account is currently being deleted.<br />
                If you want to signup for Hypefury again, please try again in a few minutes.
              </p>
            </div>
            <div v-else-if="isEmailLogin" id="firebaseui-auth-container" class="flex flex-col items-center space-y-4">
              <div class="flex flex-col w-full space-y-3">
                <input
                  type="text"
                  placeholder="Email"
                  v-model="email"
                />
                <input
                  type="password"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <button
                class="button primary-button w-full md:w-1/2"
                @click="signInWithEmail"
                :disabled="isSigningIn"
              >
                {{ isSigningIn ? 'Signing in...' : 'Sign in' }}
              </button>
              <p class="text-center text-base text-gray-80 dark-mode:text-white mt-6">
                By signing in, I agree to the
                <a href="https://hypefury.com/terms-of-service/" class="text-main-color-100" target="_blank">TOS</a>
                and
                <a href="https://hypefury.com/privacy-policy/" class="text-main-color-100" target="_blank">Hypefury Privacy Policy</a>.
              </p>
            </div>
            <div v-else>
              <button
                class="button primary-button w-full md:w-auto"
                @click.prevent="signInWithTwitter()"
                :disabled="isSigningIn"
              >
                <twitter class="mr-4" />
                {{ isSigningIn ? 'Signing in...' : 'Sign in with Twitter' }}
                <div id="firebaseui-auth-container" class="hidden" v-if="loginMode == 'twitter' && !isAccountBeingDeleted"></div>
              </button>
              <p class="text-center text-base text-gray-80 dark-mode:text-white mt-6">
                By signing in, I agree to the
                <a href="https://hypefury.com/terms-of-service/" class="text-main-color-100" target="_blank">TOS</a>
                and
                <a href="https://hypefury.com/privacy-policy/" class="text-main-color-100" target="_blank">Hypefury Privacy Policy</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div :class="onboardingStep === 0 ? 'bg-blue-20 dark-mode:bg-dark-mode-80' : 'bg-white dark-mode:bg-dark-mode-90'" class="flex-1 h-full relative px-6 xl:px-0 hidden md:block">
        <div class="flex flex-col items-center justify-center h-full" v-if="onboardingStep === 0">
          <img
            class=" h-480 w-4/5 object-contain rounded-lg lg:object-none 2xl:w-1/2 md:h-1/2"
            :src="'/images/login-' + loginImageIndex + '.png'"
          >
          <p class="dark-mode:text-white text-center my-2 w-3/5 2xl:w-1/2 lg:text-2xl md:text-lg">
            {{ loginImageDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  /* global ga */
  import { checkTheme } from '@/util/checkTheme';
  import controller from '@/controller';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/firestore';
  import 'firebase/compat/auth';
  import * as firebaseui from 'firebaseui';
  import conversionPixels from '@/conversionPixels';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  const fb = require('../../firebase');
  import twitter from '../../svgs/twitter';
  import AppLogo from '../../svgs/logo';
  import DarkLogo from '../../svgs/darkLogo';

  export default {
    data() {
      return {
        loginMode: 'twitter',
        isLoginWithTwitterExplanationShown: false,
        isPostTwitterAuth: false,
        isAccountBeingDeleted: false,
        onboardingStep: 0,
        isAutoTweet: true,
        tenTweetsKeeped: false,
        isSigningIn: false,
        isEmailLogin: false,
        email: null,
        password: null,
      };
    },
    components: {
      twitter,
      AppLogo,
      DarkLogo
    },
    mounted: function () {
      this.isEmailLogin = this.$route.query.type === 'email';
      const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      checkTheme(isDark);
      const authUI = new firebaseui.auth.AuthUI(firebase.auth());
      if (authUI.isPendingRedirect()) {
        this.isPostTwitterAuth = true;
      }
      const that = this;
      authUI.start('#firebaseui-auth-container', {
        signInOptions: [firebase.auth.TwitterAuthProvider.PROVIDER_ID],
        signInFlow: 'popup',
        callbacks: {
          uiShown: function () {
          },
          signInSuccessWithAuthResult: function (authResult) {
            const ref = fb.usersCollection.doc(authResult.user.uid);

            ref.get().then(async (doc) => {
              if (doc.data() && doc.data().isBeingDeleted === true) {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    that.isAccountBeingDeleted = true;
                    localStorage.clear();
                  });
              } else if (!doc.data()) {
                let source = null;
                if (that.$route.query.from) {
                  try {
                    const { data } = await controller.isSourceValid(authResult.user, that.$route.query.from.toLowerCase());

                    if (data) {
                      source = that.$route.query.from.toLowerCase();
                    }
                  } catch (error) {
                    console.error('An error has occurred while checking source', error);
                  }
                }

                const userData = {
                  uid: authResult.user.uid,
                  name: authResult.additionalUserInfo.profile.name,
                  photoURL: authResult.additionalUserInfo.profile.profile_image_url_https,
                  username: authResult.additionalUserInfo.profile.screen_name,
                  email: authResult.additionalUserInfo.profile.email,
                  twitterAccessTokenKey: authResult.credential.accessToken,
                  twitterAccessTokenSecret: authResult.credential.secret,
                  twitterId: authResult.additionalUserInfo.profile.id_str,
                  followersCount: authResult.additionalUserInfo.profile.followers_count,
                  followingCount: authResult.additionalUserInfo.profile.friends_count,
                  hasSignedUpFromMobile: that.isMobile,
                  source,
                };
                controller.users
                  .create(authResult.user, userData)
                  .then(() => {
                    try {
                      // First promoter integration
                      window.fpr &&
                        window.fpr('referral', {
                          email: authResult.additionalUserInfo.profile.email,
                        });
                      that.$router.push('/setup');

                      if (!navigator.doNotTrack) {
                        conversionPixels.twitter();
                      }
                    } catch (e) {
                      console.error(e);
                      that.$router.push('/setup');
                    }
                  })
                  .catch((error) => {
                    if (error.response.status === 403) {
                      console.info(`User ${userData.uid} already exists, skipping creation.`);
                      return;
                    }
                    console.error(error);
                  });
              } else {
                that.updateUserAccessTokens(authResult, doc).then(() => {
                  const nextURL = localStorage.getItem('nextURL') || '/';
                  localStorage.removeItem('nextURL');
                  that.$router.push(nextURL);
                });
              }
            });
          },
        },
      });
    },
    computed: {
      loginStatusText() {
        return this.$route.path === '/free'
          ? 'Use Hypefury for free now'
          : 'Start your free trial now';
      },
      loginImageIndex() {
        return Math.floor(Math.random() * 2);
      },
      loginImageDescription() {
        return [
          '',
          'Jeff, @Rugged_Legacy, building his empire from his backyard'
        ][this.loginImageIndex];
      },
    },
    methods: {
      showLoginWithTwitterExplanation() {
        this.isLoginWithTwitterExplanationShown = true;
        try {
          ga('send', 'pageview', { page: '/why-login', title: 'Twitter login explanation' });
        } catch (error) {
          console.log('An error has occurred while sending "pageview" to Google Analytics.', error);
        }
      },
      updateUserAccessTokens: (authResult, doc) => {
        const update = {
          twitterAccessTokenKey: authResult.credential.accessToken,
          twitterAccessTokenSecret: authResult.credential.secret,
          twitterAccessTokenValidity: {
            areTokensValid: true,
            lastUpdatedAt: new Date(),
          },
        };
        return doc.ref.update(update);
      },
      signInWithTwitter () {
        document.querySelector('#firebaseui-auth-container button').click()
        this.isSigningIn = true;
      },
      signInWithEmail() {
        this.isSigningIn = true;
        return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            setTimeout(() => {
              const nextURL = localStorage.getItem('nextURL') || '/';
              localStorage.removeItem('nextURL');
              this.$router.push(nextURL);
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            this.isSigningIn = false;
          });
      },
    },
    mixins: [MobileMixin],
  };
</script>
